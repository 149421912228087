import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "@reach/router";

import styled from "styled-components";
import API from "../utils/API";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { PRIMARY } from "../utils/colors";
import Button from "../components/shared/Button";
import JoinImage from "../images/JoinBg.png";
import logo from "../images/logo-blue.png";

const SignupContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
`;

const SignupContainer = styled.div`
  max-width: 350px;
  margin: auto;
  text-align: center;

  li {
    color: red;
    font-size: 14px;
  }

  input {
    height: 40px;
    padding: 4px;
    width: 100%;
  }

  label {
    display: block;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
    em {
      font-size: 12px;
    }
  }

  .actions {
    margin-top: 16px;
    text-align: center;

    button {
      width: 100%;
    }
  }
`;

const BgImg = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.4;
`;

const SignupHeader = styled.h1`
  text-align: center;
  font-size: 40px;

  span {
    color: ${PRIMARY};
  }
`;

const IndexPage = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const { email, password } = user;
  const loggedInUser = useSelector((state) => state.user.user);
  const usedLocation = useLocation();

  async function submit(e) {
    e.preventDefault();
    API.post("/users/sign_in", {
      user,
    })
      .then(() => {
        localStorage.setItem("fetch_user_info", JSON.stringify("true"));
        setSuccess(true);
      })
      .catch((error) => {
        setErrors([
          error?.response?.data?.error ||
            "Something went wrong. Contact team@react.school for assistance.",
        ]);
      });
  }

  function setEmail({ target: { value } }) {
    setUser({
      ...user,
      email: value,
    });
  }

  function setPassword({ target: { value } }) {
    setUser({
      ...user,
      password: value,
    });
  }

  useEffect(() => {
    if (loggedInUser || success) {
      window.location = "/curriculum";
    }
  }, [loggedInUser, success]);

  return (
    <Layout>
      <Seo title="Home" />
      <BgImg src={JoinImage} />
      <SignupContainerFlex>
        <SignupContainer>
          <>
            <img src={logo} />
            <SignupHeader>Welcome back</SignupHeader>
            {usedLocation?.search === "?just_reset_password" && (
              <span>
                {" "}
                Password reset. Please login with your new password.{" "}
              </span>
            )}
            {errors.map((err) => (
              <li>{err}</li>
            ))}
            <form onSubmit={submit}>
              <div>
                <label htmlFor="user_email">Email</label>
                <input
                  autoFocus
                  autoComplete="email"
                  type="email"
                  value={email}
                  id="user_email"
                  onChange={setEmail}
                />
              </div>

              <div className="field">
                <label htmlFor="user_password">Password</label>
                <input
                  autoComplete="new-password"
                  type="password"
                  id="user_password"
                  value={password}
                  onChange={setPassword}
                />
              </div>

              <div className="actions">
                <Button type="submit" name="commit">
                  Login
                </Button>
                <a
                  style={{ fontSize: "12px" }}
                  href="https://api.react.school/users/password/new"
                >
                  Reset your password
                </a>
              </div>
            </form>
          </>
        </SignupContainer>
      </SignupContainerFlex>
    </Layout>
  );
};

export default IndexPage;
